import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Injectable, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { flatMap } from 'rxjs/operators';
import { NgRedux } from '@angular-redux/store';
import * as _ from 'lodash';

import countries, { ICountry } from '../../../../../../../common/countries';
import timezones, { ITimezone } from '../../../../../../../common/timezones';
import { IAppState } from '../../../../redux/store';
import { LocationsController } from '../../../../redux/actions/locations/locations.controller';
import { LocationApiService } from '../../../../services/api/location.api.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IStateLocation, LocationsSelector } from '../../../../redux/reducers/locations.reducer';
import { ModalService } from '../../../../services/modal.service';
import { EQ, LOCATIONS } from '../../../../../../../common/constants';
import { IApiGetLocation, IRuntime } from '../../../../../../../common/interfaces/location';
import { AdministrationPageComponent } from '../../administration-page/administration-page.component';
import defaultRunTimes from '../../../../../../../common/runtimes';
import { Runtimes } from '../../../util/runtimes';
import { DuplicateTrackingService } from '../../../../services/duplicate-tracking-service';
import { BaSelectListItems } from 'src/app/components/ui-elements/select/select.component';
import { getReadableLastSeen } from 'src/app/components/util/location';

type AttributesConfig = {
  [code: string]: {
    name: string;
    type: 'select' | 'text',
    options?: BaSelectListItems;
  },
};

@Component({
  selector: 'app-locations-administration',
  templateUrl: './locations-administration.component.html',
  styleUrls: ['./locations-administration.component.scss'],
})
export class LocationsAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy {

  public static ROUTE = 'location-administration';

  public countries: ICountry[] = _.sortBy(countries, 'name');
  public timezones: ITimezone[] = timezones;

  public attributesConfig: AttributesConfig = {
    floor_id: {
      name: 'Floor ID',
      type: 'select',
      options: [
        { name: 'OG6', code: 'og6' },
        { name: 'OG5', code: 'og5' },
        { name: 'OG4', code: 'og4' },
        { name: 'OG3', code: 'og3' },
        { name: 'OG2', code: 'og2' },
        { name: 'OG1', code: 'og1' },
        { name: 'EG', code: 'eg' },
        { name: 'UG', code: 'ug' },
      ],
    },
    weather_id: {
      name: 'Weather ID',
      type: 'text',
    },
    dealer_id: {
      name: 'Dealer ID',
      type: 'text',
    },
  };

  public attributesOptions = Object.entries(this.attributesConfig).map(([code, { name }]) => ({ code, name }));

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    protected controller: LocationsController,
    protected apiService: LocationApiService,
    protected toastrService: ToastrService,
    protected router: Router,
    private route: ActivatedRoute,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
    private runtimesUtil: Runtimes,
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  protected validators = [];
  protected onCloseRoute = LOCATIONS;
  public model: Partial<IStateLocation> = {
    active: true,
    name: null,
    width: null,
    height: null,
    city: null,
    country: '',
    timezone: '',
    zip: null,
    street_and_number: null,
    runtimes: this.runtimesUtil.fixRunTimes(defaultRunTimes),
    attributes: [
      { key: '', value: '' },
    ],
    comment: null,
    ip: null,
    last_seen: null,
    status: 'offline',
  };

  private originalLastSeen: string | null = null;

  public onRuntimeRemove(index: number) {
    _.pullAt(this.model.runtimes, [index]);
  }

  public onRuntimeAdd() {
    // @ts-ignore
    this.model.runtimes.push({ startTime: '', endTime: '', startDay: '', endDay: '' });
  }

  protected isModelValid() {
    const isValuesValid = this.model.name && this.model.width && this.model.height && this.model.city && this.model.country && this.model.timezone;

    const isRuntimesValid = _.reduce(this.model.runtimes, (acc: boolean, runtime: IRuntime) => {
      if (!acc) {
        return acc;
      }

      return runtime.endDay as any !== '' && runtime.endTime !== '' && runtime.startDay as any !== '' && runtime.startTime !== '';
    }, true);

    const isLocationAttributesValid = this.model.attributes
      .filter(({ key }) => Boolean(key))
      .every(({ value }) => Boolean(value));

    return !!isValuesValid && !!isRuntimesValid && !!isLocationAttributesValid;
  }

  public async onSubmit() {
    try {
      this.ref.detach();
      this.model.last_seen = this.originalLastSeen;
      delete this.model.status;

      await super.onSubmit();
      this.saveLocalLocationRuntimes();
    } catch (err) {
      throw err;
    } finally {
      this.ref.reattach();
    }
  }

  protected async prepareModel() {
    if (typeof this.model.width !== 'number') {
      this.model.width = _.parseInt(this.model.width);
    }
    if (typeof this.model.height !== 'number') {
      this.model.height = _.parseInt(this.model.height);
    }

    this.model.runtimes = this.runtimesUtil.convertRunTimes(this.model.runtimes) as IRuntime[];

    const attributesExists = this.model.attributes.every(({ key }) => Boolean(key));
    this.model.attributes = attributesExists ? this.model.attributes : null;
  }

  public saveLocalLocationRuntimes() {
    this.model.runtimes = this.runtimesUtil.fixRunTimes(this.model.runtimes);
    window.localStorage.setItem('location_runtimes', JSON.stringify(this.model.runtimes));
  }

  public getLocalLocationRuntimes() {
    const runtimes = JSON.parse(window.localStorage.getItem('location_runtimes'));
    return _.map(runtimes, (runtime: IRuntime) => {

      const isTimeSavedOnly = (runtime.startTime && runtime.startTime.length <= 8) && (runtime.endTime && runtime.endTime.length <= 8);
      const fixedRunTime = this.runtimesUtil.fixRunTime(runtime);

      runtime.startTime = isTimeSavedOnly ? fixedRunTime.startTime : new Date(runtime.startTime) as any;
      runtime.endTime = isTimeSavedOnly ? fixedRunTime.endTime : new Date(runtime.endTime) as any;
      return runtime;
    });
  }

  public addLocationAttribute() {
    if (this.model.attributes.length < this.attributesOptions.length) {
      this.model.attributes = [
        ...this.model.attributes,
        { key: '', value: '' },
      ];
    }
  }

  public removeLocationAttribute(index: number) {
    this.model.attributes.splice(index, 1);
    this.prepareAttributesOptions();
  }

  public onAttributeKeyUpdate(index?: number) {
    if (index !== undefined) {
      // reset value on key change
      this.model.attributes[index].value = '';
    }
    this.prepareAttributesOptions();
    super.onFormChange();
  }

  public onAttributeValueUpdate() {
    this.prepareAttributesOptions();
    super.onFormChange();
  }

  private prepareAttributesOptions() {
    this.attributesOptions = this.attributesOptions.map(option => {
      const selected = this.model.attributes.map(({ key }) => key).filter(Boolean);
      return {
        ...option,
        isDisabled: selected.includes(option.code),
      };
    });
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(flatMap(({ id }) => {
        this.id = id;
        return this.redux.select(LocationsSelector.getById(id));
      }))
      .subscribe((location?: IStateLocation) => {
        if (!location) {
          const storedRuntimes = this.getLocalLocationRuntimes();
          if (storedRuntimes && storedRuntimes.length > 0) {
            this.model.runtimes = storedRuntimes;
          } else {
            this.saveLocalLocationRuntimes();
          }
          return;
        }

        this.originalLastSeen = location.last_seen;
        this.model = {
          ...this.runtimesUtil.getLocationModelData(location, null),
          status: location.status,
          last_seen: location.last_seen ? getReadableLastSeen(location.last_seen) : null,
        };
        this.prepareAttributesOptions();
      });
  }
}

@Injectable()
export class LocationsAdministrationDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private locationsController: LocationsController, private locationApiService: LocationApiService) {
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { id } = routeSnapshot.params;
    if (!id || LocationsSelector.getById(id)(this.redux.getState())) {
      return;
    }

    const locations: IApiGetLocation[] = await this.locationApiService.getByFiltersAndSortings([{ column: 'id', operator: EQ, value: id }]);
    if (locations.length === 0) {
      return;
    }
    const location = _.head(locations);

    return await this.redux.dispatch(location.active ? this.locationsController.updateActive() : this.locationsController.updateInActive());
  }
}
