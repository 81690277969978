import * as _ from 'lodash';
import {
  IAuth,
  ILocation,
  IPlaybackLogs,
  IQueue,
} from './interfaces/api';
import {IDefault} from './interfaces/util';

export interface IRoutesApi extends IDefault {
  oneWeek: IDefault;
  files: IDefault;
  health: IDefault;
  location: ILocation;
  bookingTemplate: IDefault;
  booking: IDefault;
  auth: IAuth;
  user: IDefault;
  role: IDefault;
  queue: IQueue;
  medias: IDefault;
  playbackLogs: IPlaybackLogs;
  externalResources: IDefault;
  categories: IDefault;
  settings: IDefault;
  specialRuntimes: IDefault;
}

export const api = {
  default: '/api',
  oneWeek: {
    default: '/one-week',
  },
  files: {
    default: '/files',
  },
  health: {
    default: '/health',
  },
  location: {
    default: '/location',
    playlist: '/:id/playlist',
    group: '/location-group',
    groupAll: '/location-group/all',
    groupWithId: '/location-group/:id',
    groupWithName: '/location-group/:name',
    lastUpdated: '/:id/last-updated',
    allData: '/:id/all-data',
  },
  bookingTemplate: {
    default: '/booking-template',
  },
  booking: {
    default: '/booking',
  },
  auth: {
    default: '/auth',
    login: '/login',
    logout: '/logout',
    refresh: '/refresh',
    location: '/location',
  },
  user: {
    default: '/user',
  },
  role: {
    default: '/role',
  },
  queue: {
    default: '/queue',
    booking: '/booking',
    puller: '/puller',
  },
  medias: {
    default: '/medias',
  },
  playbackLogs: {
    default: '/playback-logs',
    export: '/export',
    removeOld: '/days/:olderThanDays',
  },
  externalResources: {
    default: '/external-resources',
  },
  categories: {
    default: '/categories',
  },
  settings: {
    default: '/settings',
  },
  specialRuntimes: {
    default: '/special-runtimes',
  },
};

export interface IRoutesFull {
  oneWeek: string;
  files: string;
  health: string;
  location: string;
  locationGroup: string;
  locationGroupall: string;
  locationGroupwithid: string;
  locationPlaylist: string;
  locationLastupdated: string;
  locationAlldata: string;
  bookingTemplate: string;
  booking: string;
  auth: string;
  authLogin: string;
  authLogout: string;
  authRefresh: string;
  authLocation: string;
  user: string;
  role: string;
  queue: string;
  queueBooking: string;
  queuePuller: string;
  medias: string;
  playbackLogsExport: string;
  externalResources: string;
  settings: string;
  categories: string;
  specialRuntimes: string;
}

export const full: IRoutesFull = _.reduce(api, (acc: any, route: any, key: string) =>
  _.reduce(route, (routeAcc: any, routeValue: string, routeKey: string) => {
    if (key === 'default') {
      return routeAcc;
    }

    const isDefaultRoute = routeValue === route.default;
    const keyAddition = isDefaultRoute ? '' : _.capitalize(routeKey);
    const routeAddition = isDefaultRoute ? '' : routeValue;
    routeAcc[key + keyAddition] = api.default + route.default + routeAddition + '/';

    return routeAcc;
  }, acc), {});
