import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {select} from '@angular-redux/store';

import {BookingsController} from '../../../redux/actions/bookings/bookings.controller';
import {BookingsActionsCreator} from '../../../redux/actions/bookings/bookings.action';
import {IAppState} from '../../../redux/store';
import {BookingsSelector, IStateBooking} from '../../../redux/reducers/bookings.reducer';
import {IColumn} from '../table-page/table/table.component';
import {formatTime} from '../../../../../../common/moment-util';
import {IDeleteTexts} from '../../../services/modal.service';
import {BookingTemplatesSelector} from '../../../redux/reducers/booking-templates.reducer';
import {
  IApiGetBookingTemplate,
  DisplayMode,
} from '../../../../../../common/interfaces/booking';
import {BOOKINGS, DATE, END_DATE, ID, NAME, START_DATE, WITHOUT_END} from '../../../../../../common/constants';
import {BookingsProcessingSelector} from '../../../redux/reducers/bookings-processing.reducer';
import { getWeekDaysDe } from '../../util/helpers';

@Component({
  selector: 'app-bookings-table',
  template: `
    <app-table
      [deleteTexts]="deleteTexts"
      [isToggleSwitched]="false"
      [name]="name"
      [editOrAddLinkGetter]="editOrAddLinkGetter"
      [duplicateLinkGetter]="duplicateLinkGetter"
      [useDuplicate]="useDuplicate"
      [columns]="columns"
      [getAllSelector]="getAll"
      [getAllIdsSelector]="getAllIds"
      [isAllCheckedSelector]="isAllChecked"
      [getFilterByNameSelector]="getFilterByName"
      [actionFactory]="actionFactory"
      [crudController]="crudController"
      [rowEndInformation]="rowEndInformation"
      [onRowSelected]="onBookingSelected"
    >
    </app-table>`,
})
export class BookingsTableComponent implements OnInit, OnDestroy {
  private bookingTemplateIdsAndNames: {id: number, name: string}[];
  public name = BOOKINGS;

  @select(BookingTemplatesSelector.getAll) readonly bookingTemplates$: Observable<IApiGetBookingTemplate[]>;
  private bookingTemplatesSubscription: Subscription;

  @Input() onBookingSelected: Function = _.noop;
  @Input() useDuplicate: boolean;

  constructor(public crudController: BookingsController, public actionFactory: BookingsActionsCreator) {
    this.getAll = ($state: IAppState) => {
      const bookings = BookingsSelector.getAll($state);
      const bookingsProcessing = BookingsProcessingSelector.getAll($state);

      return [...bookingsProcessing, ...bookings];
    };
    this.getAllIds = BookingsSelector.getAllIds;
    this.isAllChecked = BookingsSelector.isAllChecked;
    this.getFilterByName = BookingsSelector.getFilterByName;
    this.useDuplicate = true;
  }

  public deleteTexts: IDeleteTexts = {
    delete: 'Buchung löschen',
    reallyDelete: 'Möchtest du die ausgewählte Buchung wirklich löschen?',
    noKeep: 'Nein, Buchung behalten.',
    yesDelete: 'Ja, Buchung löschen.',
  };

  public columns: IColumn[] = [
    {
      name: 'Vorlagenbezeichnung',
      filterName: 'booking_template.id',
      cellClass: 'img',
      placeholder: 'Alle',
      getSearchOptions: () => this.bookingTemplateIdsAndNames,
      dataGetter: (data) => _.get(data, 'thumbnail_url') || _.get(data, 'files[0].thumbnail_url'),
    },
    {name: 'Buchungstitel', filterName: NAME, cellClass: 'large', dataGetter: (data) => data.name},
    {
      name: 'Standort',
      filterName: 'locations.name',
      cellClass: 'large',
      multiText: 'Mehrere Standorte',
      dataHeaders: ['Standortnummer', 'Standortbezeichnung'],
      singleDataGetters: _.compact([(data) => data.id, (data) => data.name]),
      dataGetter: (data) => {
        if (data.disabled && !_.find(data.locations, (location) => !location.id)) {
          return [...data.locations, {id: null, name: '+ Standorte ohne Zugriff'}];
        }

        return data.locations.length > 1 ? data.locations : data.locations[0].name;
      },
    },
    {name: 'Beginn', filterName: START_DATE, cellClass: DATE, dataGetter: (data) => data.start_date},
    {
      name: 'Ende',
      filterName: END_DATE,
      cellClass: DATE,
      defaultData: WITHOUT_END,
      dataGetter: (data) => data.end_date,
    },
    {
      name: 'Buchungstyp',
      filterName: 'display_mode',
      cellClass: 'booking-type',
      dataGetter: this.bookingTypeName,
      placeholder: 'Alle',
      getSearchOptions: () => [{
        id: DisplayMode.Priority,
        name: 'Priorität',
      }, {
        id: DisplayMode.Standard,
        name: 'Standard',
      }, {
        id: DisplayMode.Fallback,
        name: 'Ersatz',
      }, {
        id: DisplayMode.Category,
        name: 'Fernsteuerung',
      }],
    },
  ];
  public getAll: ($state: IAppState) => IStateBooking[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => string;

  private bookingTypeName(data) {
    switch (data.display_mode) {
      case DisplayMode.Priority:
        return 'Priorität';
      case DisplayMode.Standard:
        return 'Standard';
      case DisplayMode.Fallback:
        return 'Ersatz';
      case DisplayMode.Category:
        return 'Fernsteuerung';
      default:
        return '';
    }
  }

  public editOrAddLinkGetter(rowData: any) {
    if (!_.has(rowData, 'booking_template.id')) {
      return undefined;
    }

    return `/booking-overview/booking-administration/${rowData.booking_template.id}/${rowData.id}`;
  }

  public duplicateLinkGetter(rowData: any) {
    if (!_.has(rowData, 'booking_template.id')) {
      return undefined;
    }

    return `/booking-overview/booking-administration/${rowData.booking_template.id}`;
  }

  public rowEndInformation(rowData: any) {
    const isWithTime = rowData.end_time && rowData.start_time;
    const isDaily = rowData.week_days === null || rowData.week_days.length === 7;

    let dayText = 'täglich';
    if (!isDaily) {
      const weekDaysDe = getWeekDaysDe(rowData.week_days);
      dayText = weekDaysDe.join(', ');
    }
    const timeText = (isWithTime ? `${formatTime(rowData.start_time)} - ${formatTime(rowData.end_time)}` : 'ganztägige Anzeige');

    return `${dayText}, ${timeText}`;
  }

  public ngOnInit() {
    this.bookingTemplatesSubscription = this.bookingTemplates$.subscribe((bookingTemplates: IApiGetBookingTemplate[]) => {
      this.bookingTemplateIdsAndNames = _.map(bookingTemplates, (bookingTemplate) => _.pick(bookingTemplate, [ID, NAME]));
    });
  }

  public ngOnDestroy(): void {
    this.bookingTemplatesSubscription.unsubscribe();
  }
}
